import React, { useEffect, useState, useContext } from "react";
import { Table, Button, Drawer, Modal, Checkbox, Empty } from "antd";
import Papa from "papaparse";

import Loader from "./Loader.js";

import mixpanel from "mixpanel-browser";

// LIBS
import { useTranslation } from "react-i18next";
import "../i18n";

import { AuthContext } from "../context/AuthContext.js";

// Fonction pour récupérer les leads depuis votre backend
const fetchLeads = async (teamId, keyword) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASEURL}/api/leads/get-leads?teamId=${teamId}&keyword=${keyword}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch leads");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching leads:", error);
    throw error;
  }
};

const formatContentWithHTML = (content) => {
  return content.replace(/\n/g, "<br />");
};

const highlightKeyword = (content, keyword) => {
  if (!content || !keyword) return content; // Ajout pour éviter des erreurs si `content` ou `keyword` ne sont pas définis
  return content.replace(
    new RegExp(`(${keyword})`, "gi"),
    '<span class="highlightedKeyword">$1</span>'
  );
};

import "./Leads.css";

function LeadsTable({ selectedSources, selectedLanguages }) {
  const { teamId, keyword, token, teamDetails, userInfos } =
    useContext(AuthContext);

  const { t, i18n } = useTranslation();
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedMention, setSelectedMention] = useState(null);
  const [modalVisible, setModalVisible] = useState(true);

  // Vérifier si socialInbox est activé
  // const socialInboxActivate = teamDetails?.socialInbox.activate;

  // Fonction pour filtrer les leads en fonction des filtres sélectionnés
  const applyFilters = (leads) => {
    return leads.filter((lead) => {
      // Filtrer par source
      const matchesSource =
        !selectedSources.length ||
        selectedSources.includes(lead.channel.toLowerCase());

      // Filtrer par langue
      const matchesLanguage =
        !selectedLanguages.length || selectedLanguages.includes(lead.language);

      return matchesSource && matchesLanguage;
    });
  };

  function getExcludedUrls(keywords) {
    if (!Array.isArray(keywords)) {
      return [];
    }

    return keywords.reduce((acc, keyword) => {
      if (keyword.urls && Array.isArray(keyword.urls)) {
        // Nettoyer les URLs pour enlever les variations communes
        const cleanedUrls = keyword.urls.map((url) =>
          url
            .replace(/^https?:\/\//, "")
            .replace(/www\./, "")
            .replace(/\/$/, "")
        );
        acc.push(...cleanedUrls);
      }
      return acc;
    }, []);
  }

  let excludedUrls = [];

  if (teamDetails && teamDetails.keyword) {
    excludedUrls = getExcludedUrls(teamDetails.keyword);
  }

  const handlePurchaseClick = async () => {
    if (!userInfos.stripeCustomerId) {
      // Si l'utilisateur n'a pas de stripeCustomerId, créez un client Stripe pour lui
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/user/createStripeCustomer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ email: userInfos.email }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok ");
      }

      mixpanel.track("Social_Inbox", {
        user: userInfos.email,
        Plan: "Social_Inbox",
      });

      const data = await response.json();
      const stripeCustomerId = data.stripeCustomerId;
      userInfos.stripeCustomerId = stripeCustomerId;
    }

    const paymentURL = "https://buy.stripe.com/aEU6qA4lb9zzaoU4gv";

    window.open(
      `${paymentURL}?prefilled_email=${userInfos.email}&client_reference_id=${teamId}`
    );
  };

  const LeadsModal = ({ open, onOk, onCancel, leads }) => {
    const { t } = useTranslation();
    const columns = [
      {
        title: "",
        dataIndex: "select",
        key: "select",
        render: (text, record) => <Checkbox />,
      },
      {
        title: "",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "",
        dataIndex: "icon",
        key: "icon",
        render: (icon) => <i className={icon} style={{ fontSize: "24px" }}></i>,
      },
      {
        title: "",
        dataIndex: "profile",
        key: "profile",
        render: (text) => <Button>{text}</Button>,
      },
      {
        title: "",
        dataIndex: "mention",
        key: "mention",
        render: (text) => <Button>{text}</Button>,
      },
    ];

    return (
      <Modal
        title={null}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        footer={null}
      >
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <h2 className="modalLeadInbox">
            {t("Highly_targeted_contacts")}
            <br /> {t("in_your")}{" "}
            <span className="socialColor"> {t("Social_Inbox")}</span>
          </h2>
          <p className="modalLeadp">
            {t("describe_the_intent_of_your_search")}
          </p>
          <img
            src="/social_inbox.png"
            alt="Social Inbox buska"
            style={{ maxWidth: "100%", height: "auto", marginTop: "20px" }}
          />
          <br />
          <br />
          <a
            onClick={handlePurchaseClick}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            <span className="ctaPrimaryBlack">
              {t("find_them_now")}{" "}
              <i className="ml10 fa-solid fa-arrow-right"></i>
            </span>
          </a>
          <br />
          <a
            target="_blank"
            href="https://app.lemcal.com/@buska/demo-with-our-team"
          >
            <span className="maybelater">{t("book_a_demo")}</span>
          </a>
        </div>
      </Modal>
    );
  };

  // console.log("socialInboxActivate", socialInboxActivate);

  const fetchFullMention = async (mentionId) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const url = `${process.env.REACT_APP_BASEURL}/api/leads/getFullMentionById?mentionId=${mentionId}`;
      const response = await fetch(url, { headers });

      if (!response.ok) {
        throw new Error("Failed to fetch full mention details");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching full mention:", error);
      throw error;
    }
  };

  const getIconForChannel = (channel) => {
    if (!channel) return <i className="fa-solid fa-hashtag fa-2x"></i>; // Retourne un icône par défaut si channel est indéfini

    switch (channel.toLowerCase()) {
      case "twitter":
        return <i className="twitter fa-brands fa-twitter fa-2x"></i>;
      case "facebook":
        return <i className="facebook fa-brands fa-facebook fa-2x"></i>;
      case "instagram":
        return <i className="instagram fa-brands fa-instagram fa-2x"></i>;
      case "linkedin":
        return <i className="linkedin fa-brands fa-linkedin fa-2x"></i>;
      case "reddit":
        return <i className="reddit fa-brands fa-reddit fa-2x"></i>;
      case "youtube":
        return <i className="youtube fa-brands fa-youtube fa-2x"></i>;
      case "pinterest":
        return <i className="pinterest fa-brands fa-pinterest fa-2x"></i>;
      case "indiehacker":
        return <i className="indiehacker fa-brands fa-indiehacker fa-2x"></i>;
      default:
        return <i className="fa-solid fa-hashtag fa-2x"></i>; // Retourne un icône par défaut si aucun cas correspondant
    }
  };

  useEffect(() => {
    if (!teamId || !keyword) {
      console.warn("teamId or keyword is not available yet.");
      return;
    }

    setLoading(true);
    fetchLeads(teamId, keyword)
      .then((data) => {
        const sortedLeads = [...data].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        const filteredLeads = applyFilters(sortedLeads);
        setLeads(filteredLeads);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to load leads:", error);
        setLoading(false);
      });

    mixpanel.track("Load_page", {
      user: userInfos.email,
      page: "Social Inbox",
    });
  }, [teamId, keyword, selectedSources, selectedLanguages]);

  const handleExport = async () => {
    try {
      setLoading(true);

      mixpanel.track("Export_lead", {
        user: userInfos.email,
      });

      const selectedLeads = await Promise.all(
        leads
          .filter((lead) => selectedRowKeys.includes(lead._id))
          .map(async (lead) => {
            if (lead.mentions && lead.mentions.length > 0) {
              const mentionId = lead.mentions[0];
              try {
                const fullMention = await fetchFullMention(mentionId);
                return {
                  "Lead Name": lead.name,
                  Source: lead.channel,
                  "Lead Profile URL": lead.link,
                  Content: fullMention.mention.content,
                };
              } catch (error) {
                console.error(
                  "Failed to fetch full mention for lead:",
                  lead._id
                );
                return {
                  "Lead Name": lead.name,
                  Source: lead.channel,
                  "Lead Profile URL": lead.link,
                  Content: "Error fetching full content",
                };
              }
            } else {
              return {
                "Lead Name": lead.name,
                Source: lead.channel,
                "Lead Profile URL": lead.link,
                Content: lead.contentPreview || "No content available",
              };
            }
          })
      );

      const csv = Papa.unparse(selectedLeads, {
        columns: ["Lead Name", "Source", "Lead Profile URL", "Content"],
        header: true,
      });

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute(
        "download",
        `leads-from-buska-${new Date().toLocaleDateString()}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Failed to export leads:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const showMentionPreview = async (record) => {
    try {
      // setLoading(true);
      const mentionId = record.mentions[0]; // Assumons que mentionId est disponible et correct
      const fullMention = await fetchFullMention(mentionId);
      setSelectedMention(fullMention.mention);
      setDrawerVisible(true);
    } catch (error) {
      console.error("Failed to load full mention details:", error);
    } finally {
      // setLoading(false);
    }
  };

  const onRowClick = async (record) => {
    try {
      setLoading(true);
      if (record.mentions.length > 0) {
        const mentionId = record.mentions[0];
        const fullMention = await fetchFullMention(mentionId);
        setSelectedMention(fullMention.mention);
        setDrawerVisible(true);
      } else {
        console.log("No mention IDs available in the record");
      }
    } catch (error) {
      console.error("Failed to load full mention details:", error);
    } finally {
      setLoading(false);
    }
  };

  function TwitterEmbed({ embedCode }) {
    useEffect(() => {
      // Fonction pour charger le script de widgets Twitter
      const loadTwitterScript = () => {
        if (!window.twttr) {
          const script = document.createElement("script");
          script.src = "https://platform.twitter.com/widgets.js";
          script.async = true;
          script.charset = "utf-8";
          document.body.appendChild(script);
        } else {
          window.twttr.widgets.load();
        }
      };

      loadTwitterScript();
    }, [embedCode]); // Exécuté à chaque fois que embedCode change

    return (
      <div
        className="twitter-embed"
        dangerouslySetInnerHTML={{ __html: embedCode }}
      />
    );
  }

  function getEmbeddedContent(post) {
    if (!post || !post.link) return <p>{t("No_post_selected")}</p>;

    // Extraire le nom de domaine principal de l'URL
    const domainMatch = post.link.match(/(?:https?:\/\/)?(?:www\.)?([^\/]+)/);
    const domain = domainMatch ? domainMatch[1] : null;

    // Déterminer la source à partir du domaine
    let source = "";
    if (domain.includes("twitter.com")) {
      source = "twitter";
    } else if (domain.includes("linkedin.com")) {
      source = "linkedin";
    } else if (domain.includes("youtube.com")) {
      source = "youtube";
    } else if (domain.includes("reddit.com")) {
      source = "reddit";
    } else if (domain.includes("instagram.com")) {
      source = "instagram";
    } else if (domain.includes("facebook.com")) {
      source = "facebook";
    } else {
      // Source non reconnue
      return (
        <p
          className="preserve-newlines"
          dangerouslySetInnerHTML={{
            __html: highlightKeyword(post.content, keyword),
          }}
        ></p>
      );
    }

    // Générer le contenu intégré en fonction de la source détectée
    switch (source) {
      case "twitter":
        const tweetIdMatch = post.link.match(/status\/(\d+)/);
        const tweetId = tweetIdMatch ? tweetIdMatch[1] : null;

        const twitterLink = post.link.replace("x.com", "twitter.com");

        if (tweetId) {
          const twitterEmbedCode = `<blockquote class="twitter-tweet">
        <p>${t("loading")}</p>
        <a href="${twitterLink}"></a>
      </blockquote>`;
          return <TwitterEmbed embedCode={twitterEmbedCode} />;
        }
        break;
      case "linkedin":
        const iframeSrc = post.link.replace("feed/update", "embed/feed/update");
        return (
          <div className="alignEmbed rounded">
            <iframe
              src={iframeSrc}
              height="833"
              width="504"
              frameBorder="0"
              allowFullScreen=""
            ></iframe>
          </div>
        );
      default:
        return (
          <p
            className="preserve-newlines"
            dangerouslySetInnerHTML={{
              __html: highlightKeyword(post.content, keyword),
            }}
          ></p>
        );
    }
  }

  const formattedContent = selectedMention
    ? highlightKeyword(formatContentWithHTML(selectedMention.content), keyword)
    : "";

  const onRow = (record) => ({
    onClick: () => onRowClick(record),
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const capitalizeFirstLetter = (text) => {
    if (!text) return ""; // Si le texte est vide ou indéfini, retourner une chaîne vide
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase(); // Capitalise la première lettre et met le reste en minuscule
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const columns = [
    {
      title: "Lead name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      render: (text) => (
        <span className="textName">
          {capitalizeFirstLetter(truncateText(text, 25))}
        </span>
      ),
    },
    {
      title: "Social channel",
      dataIndex: "channel",
      key: "channel",
      render: (channel) => getIconForChannel(channel),
    },
    {
      title: "Lead profile",
      dataIndex: "link",
      key: "link",
      render: (text) => (
        <a
          className="boutonLead"
          href={text}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="icon fa-regular fa-user"></i>
          <span className="textBouton">Profile</span>
        </a>
      ),
    },

    {
      title: "Mention preview",
      dataIndex: "contentPreview",
      key: "mention",
      render: (contentPreview, record) => {
        if (!contentPreview) {
          return <>{t("No_content_available")}</>;
        }
        return (
          <a onClick={() => showMentionPreview(record)}>
            <span className="MentionContent">{contentPreview}</span>
          </a>
        );
      },
    },
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px 65px",
          }}
        >
          <Button
            className="exportButton"
            onClick={handleExport}
            disabled={!selectedRowKeys.length}
            type="primary"
          >
            <span className="textButtonExport">
              Export{" "}
              {selectedRowKeys.length ? `${selectedRowKeys.length} ` : ""}
              lead(s) as CSV
            </span>
          </Button>
        </div>

        <Table
          rowKey={(record) => record._id}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={leads}
          loading={loading}
          locale={{
            emptyText: (
              <Empty
                description={t(
                  "we_are_looking_for_the_perfect_qualified_leads"
                )}
              />
            ),
          }}
        />

        <Drawer
          width={550}
          className="leadDrawer"
          style={{ top: "15px", bottom: "15px", right: "15px" }}
          placement="right"
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          bodyStyle={{ backgroundColor: "white" }}
        >
          <div className="contentWrapper">
            {/* {selectedMention ? (
            <p
              className="preserve-newlines"
              dangerouslySetInnerHTML={{ __html: formattedContent }}
            ></p>
          ) : (
            t("No_lead_selected")
          )} */}

            {selectedMention && getEmbeddedContent(selectedMention)}
            <br />
            <br />
            <br />
            {selectedMention && selectedMention.link ? (
              <a
                href={selectedMention.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="ctaResumeMention">
                  <i className="fa-solid fa-up-right-from-square"></i>{" "}
                  {t("Reply")}
                </button>
              </a>
            ) : null}

            {/* <button className="ctaResumeMention">
                        <i className="fa-solid fa-wand-magic-sparkles"></i>{" "}
                        Resume this mention
                      </button> */}

            {/* <button
            className="ctaResumeMention"
            onClick={() => showModal(selectedMention)}
          >
            <i className="fa-solid fa-share-nodes"></i>{" "}
            {t("Share_this_mention")}
          </button> */}
          </div>
          <div className="bottomDrawer">
            <img src="/delivery.svg" />
          </div>
        </Drawer>
      </>

      {/* <LeadsModal
        open={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
      /> */}
    </>
  );
}

export default LeadsTable;
